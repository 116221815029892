import { useEffect } from 'react'
import Hero from '../Hero/Hero'
import MyStack from '../MyStack/MyStack'
import Projects from '../Projects/Projects'

const Home = () => {
  
  useEffect(() => {
    document.title = `Mohit Agarwal | Portfolio`
  },[])

  return (
    <div className='home'>
      <Hero center={true}/>
      <MyStack />
      <Projects />
    </div>
  )
}

export default Home
