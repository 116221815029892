import { BsFillBuildingFill } from "react-icons/bs"
import { GoLinkExternal } from "react-icons/go"

const AboutDetailCard = ({ data }) => {
  return (
    <div className='about-detail'>
      <div className="top">
        <div className="heading">{data?.heading}</div>
        <span className="success">{data?.success}</span>
      </div>
      <div className="foot">
        <div>
            <div className="flex">
                {data?.subHeadingLink !== undefined ? 
                  <a href={data?.subHeadingLink} target="_blank"><BsFillBuildingFill /> {data?.subHeading} <GoLinkExternal style={{color:'#13B0F5'}}/></a> :
                  <span><BsFillBuildingFill /> {data?.subHeading}</span>}
            </div>
        </div>
        <div>
            <div className="flex">
                <span>{data?.time}</span>
            </div>
        </div>
      </div>
    </div>
  )
}

export default AboutDetailCard
