import { BiLogoGithub, BiLogoLinkedinSquare } from "react-icons/bi"
import { FaSquareXTwitter } from "react-icons/fa6"
import { Link } from "react-router-dom"
import { WEB_ROUTES } from "../Utils/ROUTES"
import { DATA } from "../../JSON/JSON_DATA"
import { lastUpdated } from '../../lastUpdated';
import { SiLeetcode } from "react-icons/si";

const Footer = () => {
  return (
    <div className="footer-mn">
      <div className="top">
        <div>
          <span className="last-updated usn" style={{userSelect: 'none'}}>Site Last Updated : {lastUpdated}</span>
        </div>
        <div>
            { DATA?.mobile && <span className="footer-text">{DATA?.mobile}</span> }
            <span className="footer-text">{DATA?.email}</span>
            <div>
                <Link to={DATA?.social_media?.twitter} target="_blank"><FaSquareXTwitter id="footer-icn"/></Link>
                <Link to={DATA?.social_media?.github} target="_blank"><BiLogoGithub id="footer-icn"/></Link>
                <Link to={DATA?.social_media?.linkedin} target="_blank"><BiLogoLinkedinSquare id="footer-icn"/></Link>
                <Link to={DATA?.social_media?.leetcode} target="_blank"><SiLeetcode id="footer-icn"/></Link>
            </div>
        </div>
      </div>
      <div className="bottom">
        <ul>
          {
            WEB_ROUTES?.map((route, index) =><li key={index}><Link to={route?.link} className="footer-text usn">{route?.label}</Link></li> )
          }
        </ul>
      </div>
    </div>
  )
}

export default Footer
