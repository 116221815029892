import PageLayout from '../../Layout/PageLayout'
import IconHTML from "../../Icons/IconHTML"
import IconCSS from "../../Icons/IconCSS"
import IconJS from "../../Icons/IconJS"
import IconReact from "../../Icons/IconReact"
import IconRedux from "../../Icons/IconRedux"
import IconBOOTSTRAP from "../../Icons/IconBOOTSTRAP"
import IconTailwind from "../../Icons/IconTailwind"
import IconGIT from "../../Icons/IconGIT"
import IconVSCODE from "../../Icons/IconVSCODE"
import IconGITHUB from "../../Icons/IconGITHUB"
import Tooltip from '../../HOC/Tooltip'
import { useEffect } from 'react'
import IconNextjs from '../../Icons/IconNextjs'
import IconAngular from '../../Icons/IconAngular'
import IconTS from '../../Icons/IconTS'

const MyTech = () => {
  
  useEffect(() => {
    document.title = `Experience In - Mohit Agarwal | Portfolio`
    
  },[])

  return (
    <PageLayout>
      <div className="my-tech">
        <section>
          <h2 className="heading-text">My Tech Stack</h2>
          <p className="content-text">Technologies I've been working with recently</p>
        </section>
        <section>
          <div className="icons-list">
            <Tooltip title="ReactJS"><IconReact id='tech-icn'/></Tooltip>

            <Tooltip title="NextJS"><IconNextjs id='tech-icn'/></Tooltip>            

            <Tooltip title="Angular"><IconAngular id='tech-icn'/></Tooltip>

            <Tooltip title="Github"><IconGITHUB id='tech-icn'/></Tooltip>

            <Tooltip title="HTML"><IconHTML id='tech-icn'/></Tooltip>

            <Tooltip title="CSS"><IconCSS id='tech-icn'/></Tooltip>

            <Tooltip title="JavaScript"><IconJS id='tech-icn'/></Tooltip>

            <Tooltip title="TypeScript"><IconTS id='tech-icn'/></Tooltip>

            <Tooltip title="Redux"><IconRedux id='tech-icn'/></Tooltip>

            <Tooltip title="Bootstrap"><IconBOOTSTRAP id='tech-icn'/></Tooltip>

            <Tooltip title="Tailwind"><IconTailwind id='tech-icn'/></Tooltip>

            <Tooltip title="Git"><IconGIT id='tech-icn'/></Tooltip>

            <Tooltip title="VS Code"><IconVSCODE id='tech-icn'/></Tooltip>
          </div>
        </section>
      </div>
    </PageLayout>
  )
}

export default MyTech