import img1 from "../Assets/ProjectsImages/LMS/img1.png"
import img2 from "../Assets/ProjectsImages/LMS/img2.jpg"
import img3 from "../Assets/ProjectsImages/LMS/img3.jpg"
import img4 from "../Assets/ProjectsImages/LMS/img4.png"
import clock1 from "../Assets/ProjectsImages/NPM/clock1.png"
import clock2 from "../Assets/ProjectsImages/NPM/clock2.png"
import clock3 from "../Assets/ProjectsImages/NPM/clock3.png"
import table1 from "../Assets/ProjectsImages/NPM/table1.png"
import table2 from "../Assets/ProjectsImages/NPM/table2.png"
import table3 from "../Assets/ProjectsImages/NPM/table3.png"
import GAME1 from "../Assets/ProjectsImages/GAME/GAME1.png"
import GAME2 from "../Assets/ProjectsImages/GAME/GAME2.png"
import GAME3 from "../Assets/ProjectsImages/GAME/GAME3.png"
import GAME4 from "../Assets/ProjectsImages/GAME/GAME4.png"
import OMNI1 from "../Assets/ProjectsImages/OMNI/OMNI1.png"
import OMNI2 from "../Assets/ProjectsImages/OMNI/OMNI2.png"
import OMNI3 from "../Assets/ProjectsImages/OMNI/OMNI3.png"
import OMNI4 from "../Assets/ProjectsImages/OMNI/OMNI4.png"
import BLINK1 from "../Assets/ProjectsImages/BLINKX/BLINK1.png"
import BLINK2 from "../Assets/ProjectsImages/BLINKX/BLINK2.png"
import BLINK3 from "../Assets/ProjectsImages/BLINKX/BLINK3.png"
import BLINK4 from "../Assets/ProjectsImages/BLINKX/BLINK4.png"
import FARM1 from "../Assets/ProjectsImages/FARMSANTA/FARM1.png"
import FARM2 from "../Assets/ProjectsImages/FARMSANTA/FARM2.png"
import FARM3 from "../Assets/ProjectsImages/FARMSANTA/FARM3.png"
import FARM4 from "../Assets/ProjectsImages/FARMSANTA/FARM4.png"
import SKILL1 from "../Assets/ProjectsImages/SKILL/SKILL1.png"
import SKILL2 from "../Assets/ProjectsImages/SKILL/SKILL2.png"
import SKILL3 from "../Assets/ProjectsImages/SKILL/SKILL3.png"
import SKILL4 from "../Assets/ProjectsImages/SKILL/SKILL4.png"
import LUDO from "../Assets/ProjectsImages/LUDO/LUDO.png"

export const PROJECTS = [
    {
        name: "LMS (Learning Management System)",
        category: "Frontend",
        type:"Company",
        subHeading:"A Full Stack Learning Management System",
        imagesList:[
            {img: img1, label: "Image One"},
            {img: img2, label: "Image Two"},
            {img: img3, label: "Image Three"},
            {img: img4, label: "Image Four"},
        ],
        routeLink: "learning-management-system",
        detailedDescription:[
            "Developed a Learning Management System (LMS) as part of my role, leveraging modern web technologies to create an intuitive and efficient platform for managing educational content and user interactions.",
            "<b>React.js</b>: Utilized for building the dynamic and responsive user interface, ensuring a seamless experience across different devices.",
            "<b>TypeScript</b>: Implemented to enhance code quality and maintainability through strong typing and early error detection.",
            "<b>Tailwind CSS</b>: Employed for rapid and customizable styling, enabling a clean and modern design with consistent user interface elements."
        ],
        techUsed: ["HTML","JavaScript","ReactJS"],
        deployedLink :"https://react-lms.netlify.app/",
        codeLink: "https://www.github.com/25mohit"
    },
    {
        name: "SkillSwap",
        category: "fullstack",
        type: "Personal",
        subHeading:"A Full Stack web app of SkillSwap",
        imagesList:[
            {img: SKILL1, label: "Image One"},
            {img: SKILL2, label: "Image Two"},
            {img: SKILL3, label: "Image Three"},
            {img: SKILL4, label: "Image Four"},
        ],
        routeLink: "skillswap-fullstack",
        detailedDescription:[
            "SkillSwap is an innovative web application built with ReactJS, designed to facilitate a dynamic exchange of knowledge and expertise among users. With SkillSwap, users can seamlessly connect with others to share their skills, learn new ones, and foster a collaborative learning community.",
            "<p><b>Skill Exchange Platform:</b> SkillSwap provides a platform where users can list their skills and expertise, creating a diverse marketplace for knowledge exchange.</p>",
            "<p><b>User-friendly Interface:</b> The intuitive and user-friendly interface ensures a smooth navigation experience, making it easy for users to explore, connect, and engage in skill-sharing activities. </p>",
            "<p><b>Personalized Profiles: </b>Users can create personalized profiles, highlighting their skills, experience, and areas of interest. This allows for meaningful connections and ensures that users find the right match for their skill exchange.</p>",
            "<p><b>Responsive Design: </b>SkillSwap is designed to be accessible across devices, ensuring a seamless experience whether users are accessing the platform from a desktop, tablet, or smartphone.</p>",
            "SkillSwap redefines the way individuals acquire and share knowledge, creating a vibrant community where learning knows no bounds. Join SkillSwap today and embark on a journey of continuous skill enhancement and collaborative knowledge exchange!"
        ],
        techUsed: ["HTML", "JavaScript", "ReactJS"],
        deployedLink :"https://skill-swap.netlify.app/",
        codeLink: "https://www.github.com/25mohit"
    },
    {
        name: "LUDO Game",
        category: "front end game",
        type:"Personal",
        subHeading:"Multiplayer Ludo Game with Database Integration, so you can come back later and start your game.",
        imagesList:[
            {img: LUDO, label: "Image One"}
        ],
        routeLink: "ludo-game",
        detailedDescription:[
            "Designed and developed a Ludo game using React, marking one of the most complex tasks in my career. This project showcases my ability to tackle intricate UI/UX challenges and create an engaging, interactive gaming experience.",
            "<b>React.js</b>: Employed to build a responsive and dynamic user interface, enabling real-time interactions and game updates.",
            "<b>TypeScript</b>:  Utilized for robust type-checking and maintaining code quality throughout the development process.",
            "<b>Tailwind CSS</b>: Applied for styling to ensure a visually appealing and user-friendly game board with responsive design elements."
        ],
        techUsed: ["React.js","TailwindCSS","JavaScript", "TypeScript"],
        deployedLink :"http://luddo.fun",
        codeLink: "https://github.com/25mohit/LUDO"
    },
    {
        name: "Job Find App",
        category: "mobile app",
        type: "Personal",
        subHeading:"React Native App",
        imagesList:[
            {img: SKILL1, label: "Image One"},
            {img: SKILL2, label: "Image Two"},
            {img: SKILL3, label: "Image Three"},
            {img: SKILL4, label: "Image Four"},
        ],
        routeLink: "job-find-app-react-native",
        detailedDescription:[
            "I developed 'Find Job,' a comprehensive React Native application designed to simplify the job search and application process. This app provides users with a streamlined experience for registering, signing in, searching for job opportunities, and applying for positions.",
            "<b>User Registration and Sign-In</b>: Allows users to create accounts and securely sign in to access personalized job search features.",
            "<b>Job Search</b>: Enables users to search for job listings based on various criteria such as location, job type, and industry.",
            "<b>Application Process</b>: Users can apply for jobs directly through the app, streamlining the application process and enhancing user convenience.",
            "<b>React Native</b>: Utilized to build a cross-platform mobile application, ensuring a smooth experience on both iOS and Android devices.",
            "<b>API Integration</b>: Integrated various APIs to fetch and manage job listings, user data, and application processes, providing real-time updates and functionality."
        ],
        techUsed: ["ReactNative", "TypeScript"],
        deployedLink :"https://expo.dev/accounts/agarwal/projects/ReactNativePractice",
        codeLink: "https://github.com/25mohit/ReactNativeJob"
    },
    {
        name: "Farmsanta",
        category: "Frontend",
        type:"Company",
        subHeading:"A frontend project for Managing Farmers related services and queries.",
        imagesList:[
            {img: FARM1, label: "Image One"},
            {img: FARM2, label: "Image Two"},
            {img: FARM3, label: "Image Three"},
            {img: FARM4, label: "Image Four"},
        ],
        routeLink: "farmsanta-fullstack",
        detailedDescription:[
            "FarmSanta is a unique end to end Agritech platform developed by Singapore based Organization specialized in agriculture value chain with the philosophy to enable and empower farmers through technology, information, Advisory and data science.",
            "Farmsanta is creating the integrated Agri-food Value Chain to synergize complementary skillsets of all key stakeholders in the business process- Farmers, Govt and Trade. This platform is designed to increase process efficiency and competitive advantage through collaboration in a venture that links farmers, Agri specialists, Govt, NGOs, processors, marketers, suppliers, food service companies, retailers, financiers and more to bring full range of services and support to the marginal farmers.",
            "Smart farming is known as different use of data collected by different types of technologies used in the agriculture sector. However, it is also about how this technology integrates and works across the supply chain, all the way from the farm to the consumer.",
            "Smart Agriculture can help with the day-to-day management of farms and improve traceability, security, and automation with real time live data. It includes monitoring animals, prescriptive fertiliser programs and geospatial mapping.",
            "FarmSanta is an AI-powered crop doctor system available through an app on Android smartphones. It helps farmers protect their crops by determining the advice solutions to spray agrochemicals through immediate and localized advice."
        ],
        techUsed: ["HTML", "JavaScript", "ReactJS"],
        deployedLink :"https://react-farmsanta.vercel.app/",
        codeLink: "https://www.github.com/25mohit"
    },
    {
        name: "BlinkX - Loan on Mutual Fund",
        category: "Frontend",
        type:"Company",
        subHeading:"Get Loan on Mutual Fund Portolio by JM Financial.",
        imagesList:[
            {img: BLINK1, label: "Image One"},
            {img: BLINK2, label: "Image Two"},
            {img: BLINK3, label: "Image Three"},
            {img: BLINK4, label: "Image Four"},
        ],
        routeLink: "blinks-loan-on-mutual-fund",
        detailedDescription:[
            "Developed 'BlinkX,' a sophisticated Next.js application that incorporates complex nested API integrations to provide users with a seamless experience for managing and leveraging their mutual fund data.",
            "<b>User Authentication</b>: Users can log in using their mobile number and provide their email ID.",
            "<b>Mutual Fund Data Integration</b>: The app fetches and consolidates mutual fund data from third-party services, 'CAMS' and 'KFintech,' based on the user-provided information.",
            "<b>Loan Application</b>: Users can apply for a loan against their mutual fund investments directly through the app.",
            "<b>Next.js</b>: Utilized for building a server-side rendered React application, optimizing performance and SEO.",
            "<b>Complex Nested API Integration</b>: Implemented intricate API integrations to interact with external services and manage data flows effectively."
        ],
        techUsed: ["NextJS", "JavaScript", "TailwindCSS"],
        deployedLink :"https://blink-x-next-js.vercel.app/signup",
        codeLink: ""
    },
    {
        name: "Omni Channel POS",
        category: "Frontend",
        type:"Company",
        subHeading:"Omni Channel is Dashboard which provide many services like POS, managing billing, buyers.",
        imagesList:[
            {img: OMNI1, label: "Image One"},
            {img: OMNI2, label: "Image Two"},
            {img: OMNI3, label: "Image Three"},
            {img: OMNI4, label: "Image Four"},
        ],
        routeLink: "omni-channel-pos-system",
        detailedDescription:[
            "The purpose of omnichannel is to create an interconnected experience, providing customers with seamless service, regardless of whether they are using email, social media, telephone, or even in-person channels.",
            "Omnichannel integrates available customer service channels to provide continuity and connectivity across communication channels.",
            "This allows customers to move freely between channels without experiencing interruptions or having to repeat context from interactions on previous channels.",
            "In short, it provides a single, consistent customer journey, no matter how the customer chooses to make contact."
        ],
        techUsed: ["JavaScript", "AXIOS", "Next.js", "TailwindCSS"],
        deployedLink :"https://omni-channel-dashboard.vercel.app/",
        codeLink: ""
    },
    {
        name: "my-reactjs-table",
        category: "NPM",
        type:"Personal",
        subHeading:"Light weight yet powerfull npm package.",
        imagesList:[
            {img: table1, label: "Image One"},
            {img: table2, label: "Image Two"},
            {img: table3, label: "Image Three"},
        ],
        routeLink: "my-reactjs-table",
        detailedDescription:[
            "<b>my-reactjs-table</b> is a light weight yet most powerfull yet simple yet fully customizable ReactJS Table Component. <b>my-reactjs-table</b> npm package size is < 37 KB"
        ],
        techUsed: ["JavaScript"],
        deployedLink :"https://www.npmjs.com/package/my-reactjs-table",
        codeLink: ""
    },
    {
        name: "Random Number Guess",
        category: "front end game",
        type:"Personal",
        subHeading:"Guess Random Number ",
        imagesList:[
            {img: GAME1, label: "Image One"},
            {img: GAME2, label: "Image Two"},
            {img: GAME3, label: "Image Three"},
            {img: GAME4, label: "Image Four"},
        ],
        routeLink: "random-number-guess-game",
        detailedDescription:[
            "This game is Developed in ReactJS Library, with using ReactJS Hooks like : useState, useRef, useEffect, useReducer and also very famous and most usable State-Managment Library Redux.",
            "This is a Single Page Web Application, fully working also fully Responsible."
        ],
        techUsed: ["React.js","TailwindCSS","JavaScript"],
        deployedLink :"https://random-number-guess.vercel.app/",
        codeLink: "https://github.com/25mohit/Random-Number-Guess-Game"
    },
    {
        name: "my-react-clock",
        category: "NPM",
        type:"Personal",
        subHeading:"Omni Channel is Dashboard which provide many services like POS, managing billing, buyers.",
        imagesList:[
            {img: clock1, label: "Image One"},
            {img: clock2, label: "Image Two"},
            {img: clock3, label: "Image Three"},
        ],
        routeLink: "my-react-clock",
        detailedDescription:[
            "<b>my-react-clock</b> is a powerful ReactJS package that empowers developers to effortlessly integrate stunning and fully customizable clock components into their web applications.",
            "Whether you need to display a sleek analog clock, a modern digital clock, or a unique combination of both, <b>my-react-clock</b> has got you covered."
        ],
        techUsed: ["JavaScript"],
        deployedLink :"https://www.npmjs.com/package/my-react-clock",
        codeLink: ""
    }
]