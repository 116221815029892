

const IconGIT = ({id}) => {
  return (
    <svg id={id} xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 105 105" fill="none">
  <g clipPath="url(#clip0_9_40)">
    <path d="M103.02 47.8219L57.1758 1.98038C54.5372 -0.6598 50.2552 -0.6598 47.6134 1.98038L38.0937 11.5001L50.1699 23.5763C52.9762 22.6281 56.1943 23.2642 58.4313 25.5012C60.6789 27.7525 61.3106 30.9981 60.3393 33.8134L71.9775 45.452C74.7932 44.482 78.0421 45.1095 80.291 47.3621C83.434 50.5039 83.434 55.596 80.291 58.7399C77.1471 61.8841 72.055 61.8841 68.9095 58.7399C66.5454 56.3733 65.9613 52.9013 67.1582 49.9888L56.3046 39.1352L56.3034 67.6973C57.0902 68.0863 57.8088 68.6002 58.4313 69.2189C61.5743 72.3607 61.5743 77.4532 58.4313 80.5995C55.2874 83.7422 50.1929 83.7422 47.0535 80.5995C43.9105 77.4532 43.9105 72.3611 47.0535 69.2193C47.8067 68.4658 48.7019 67.869 49.6871 67.4635V38.6352C48.7004 38.2324 47.8048 37.6353 47.0535 36.8793C44.6722 34.5013 44.0996 31.0071 45.3198 28.0831L33.4154 16.1775L1.98105 47.6099C-0.660352 50.2525 -0.660352 54.5349 1.98105 57.1755L47.8259 103.018C50.4652 105.658 54.7464 105.658 57.3891 103.018L103.019 57.3884C105.66 54.747 105.66 50.4621 103.019 47.8219" fill="#DE4C36"/>
  </g>
  <defs>
    <clipPath id="clip0_9_40">
      <rect width="105" height="105" fill="white"/>
    </clipPath>
  </defs>
</svg>
  )
}

export default IconGIT
