import { PROJECTS } from "../../../JSON/ProjectsData"
import ProjectFilterBar from "../../HOC/ProjectFilterBar"
import PageLayout from "../../Layout/PageLayout"
import ProjectCard from "../../Utils/ProjectCard"
import { useEffect, useState } from "react"

const Projects = () => {

  const [currentFilter, setCurrentFilter] = useState('')
  const [filteredProjects, setFilteredProjects] = useState([])

  useEffect(() => {
    document.title = `All Projects - Mohit Agarwal | Portfolio`
    
  },[])

  useEffect(() => {
    if(currentFilter === "") setFilteredProjects(PROJECTS)
    else setFilteredProjects(PROJECTS?.filter(proj => proj?.category === currentFilter))
  },[currentFilter])

  return (
    <PageLayout>
      <div className="my-tech">
          <section>
              <h2 className="heading-text">Projects</h2>
              <p className="content-text">Projects I've been works on so far</p>
          </section>
          <ProjectFilterBar setCurrentFilter={setCurrentFilter} filteredProjects={filteredProjects}/>
          <section className="projects-container">
            {
              filteredProjects?.map((project, index) => <ProjectCard key={index} project={project}/>)
            }
          </section>
      </div>
    </PageLayout>
  )
}

export default Projects