const Tooltip = props => {
  return (
    <div className="tooltip">
        <div className="item">
        <span className="heading">{props?.title}</span>
            {props?.children}
        </div>
    </div>
  )
}

export default Tooltip