import { DATA } from "../../../JSON/JSON_DATA"
import PageLayout from "../../Layout/PageLayout"
import { useEffect } from "react"

const Contact = () => {
  
  useEffect(() => {
    document.title = `Contact Me - Mohit Agarwal | Portfolio`
  },[])

  return (
    <PageLayout>
      <div className="contact-container">
        <h1>For any questions please mail me :</h1>
        <h1 className="colored-text">{DATA?.email}</h1>
      </div>
    </PageLayout>
  )
}

export default Contact
