import { Link } from 'react-router-dom'
import { FaSquareXTwitter } from "react-icons/fa6"
import { BiLogoGithub, BiLogoLinkedinSquare } from "react-icons/bi"
import { FaHamburger } from "react-icons/fa";
import { DATA } from '../../JSON/JSON_DATA';
import { SiLeetcode } from 'react-icons/si';

const Navbar = ({ setShowMobileNavbar, WEB_ROUTES }) => {

  return (
    <div className='navbar'>
      <Link to='/'>
        <div className="logo">
          {/* <h3 className="colored-text">&#123; 007 &#125;</h3> */}
          <h3 className="colored-text">&#123; mohit agarwal &#125;</h3>
          {/* <span>(BETA)</span> */}
        </div>

      </Link>
      <Link to="https://code.mohitagarwal.dev" title='Click on this to checkout Version2 of my Portfolio'>v2</Link>
      <FaHamburger id="ham-icon" onClick={() => setShowMobileNavbar(true)}/>
      <div className="items">
        <ul>
          {
            WEB_ROUTES?.map((route, index) =><li key={index}><Link to={route?.link}>{route?.label}</Link></li> )
          }
        </ul>
        <Link to={DATA?.social_media?.github} target='_blank'><BiLogoGithub id='nav-icn'/></Link>
        <Link to={DATA?.social_media?.linkedin} target='_blank'><BiLogoLinkedinSquare id='nav-icn'/></Link>
        <Link to={DATA?.social_media?.twitter} target='_blank'><FaSquareXTwitter id='nav-icn'/></Link>
        <Link to={DATA?.social_media?.leetcode} target="_blank"><SiLeetcode id="nav-icn"/></Link>
      </div>
    </div>
  )
}

export default Navbar
