import { FaRegWindowClose } from "react-icons/fa";

const FullImageContainer = ({ imageToView, setImageToView }) => {
  return (
    <div className="full-image-container">
        <div className="img-cntrs">
            <FaRegWindowClose id="close-icon" onClick={() => setImageToView('')}/>
            <img src={imageToView} alt="" />
        </div>
    </div>
  )
}

export default FullImageContainer