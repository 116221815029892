

const IconTailwind = ({id}) => {
  return (
    <svg id={id} xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 131 131" fill="none">
  <path d="M36.8438 56.0844C40.6646 40.801 50.2167 33.1594 65.5 33.1594C88.425 33.1594 91.2906 50.3531 102.753 53.2187C110.395 55.1292 117.081 52.2635 122.812 44.6219C118.992 59.9052 109.44 67.5469 94.1562 67.5469C71.2313 67.5469 68.3656 50.3531 56.9031 47.4875C49.2615 45.5771 42.575 48.4427 36.8438 56.0844ZM8.1875 90.4719C12.0083 75.1885 21.5604 67.5469 36.8438 67.5469C59.7687 67.5469 62.6344 84.7406 74.0969 87.6062C81.7385 89.5167 88.425 86.651 94.1562 79.0094C90.3354 94.2927 80.7833 101.934 65.5 101.934C42.575 101.934 39.7094 84.7406 28.2469 81.875C20.6052 79.9646 13.9187 82.8302 8.1875 90.4719Z" fill="#44A8B3"/>
</svg>
  )
}

export default IconTailwind
