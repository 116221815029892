import { useState } from "react";
import { CiSquareChevLeft, CiSquareChevRight } from "react-icons/ci";
import FullImageContainer from "../Utils/FullImageContainer";

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [imageToView, setImageToView] = useState('')

  const onLeftHandler = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  }

  const onRightHandler = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  }

  return (
    <div className="image-slider">
      {imageToView !== "" && <FullImageContainer imageToView={imageToView} setImageToView={setImageToView}/>}
      <div className="left">
        <CiSquareChevLeft id="icon" onClick={onLeftHandler}/>
      </div>
      <div className="container">
        <img onClick={() => setImageToView(images?.[currentIndex]?.img)} src={images?.[currentIndex]?.img} alt={images?.[currentIndex]?.label} key={currentIndex} />
      </div>
      <div className="right">
        <CiSquareChevRight id="icon" onClick={onRightHandler}/>
      </div>
    </div>
  )
}

export default ImageSlider