import SectionWrapper from '../Layout/SectionWrapper'
import Section from '../Layout/Section'
import ProjectCard from '../Utils/ProjectCard'
import { PROJECTS } from '../../JSON/ProjectsData'
import { Link } from 'react-router-dom'

const Projects = () => {

  return (
    <SectionWrapper>
      <Section heading="Projects" subHeading="Things I've built so far">
        <div className="projects-list">
          {
            PROJECTS?.slice(0,3)?.map((project, ind) => <ProjectCard key={ind} project={project}/>)
          }
        </div>
        <Link to="projects" className="footer-text">View All</Link>
      </Section>
    </SectionWrapper>
  )
}

export default Projects
