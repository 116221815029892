import { useEffect } from "react"

const PageLayout = props => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  },[])

  return (
    <div className="layout">{props.children}</div>
  )
}

export default PageLayout
