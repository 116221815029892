import SectionWrapper from "../Layout/SectionWrapper"
import myImg from "../images/img1.png"

const Hero = ({ center }) => {

  const onClickHandler = () => {
    const link = document.createElement('a');
    link.href = '/Mohit-Agarwal-Resume.pdf';
    link.download = 'Mohit Agarwal - Resume.pdf';
    link.click();
  }
  return (
    <SectionWrapper center={center}>
        <div className="hero">
            <div className="left">
                <h1>Hi 👋, <br />My name is<br /><span className="colored-text">Mohit Agarwal</span><br />I build things for web</h1>
            </div>
            <div className="right">
                <img src={myImg} alt="Mohit Agarwal Front End Developer" className="img"/>
            </div>
        </div>
        {/* <button className="btn" onClick={onClickHandler}>Resume <IoCloudDownloadOutline /></button> */}
    </SectionWrapper>
  )
}

export default Hero
