import { useEffect } from 'react'
import PageLayout from '../../Layout/PageLayout'
import AboutDetailCard from '../../Utils/AboutDetailCard'

const About = () => {

    const collegeContent = {
        heading:"Bachelor in Commerce",
        success: "Full Time",
        subHeading:"SS Jain Subodh College, Jaipur",
        time:"Aug 2014 - Dec 2017"
    }

    const experienceList = [
        {
            heading:"Senior Front End Developer",
            success: "Full Time",
            subHeading:"Bhaktgan Ventures Pvt. Ltd., Jaipur",
            subHeadingLink:"https://www.bhaktgan.com/",
            time:"Mar 2024 - Still"
        },
        {
            heading:"Front End Developer",
            success: "Full Time",
            subHeading:"Infoware India, Ahmedabad",
            subHeadingLink:"https://www.linkedin.com/company/infowareindia",
            time:"Jul 2022 - Mar 2024"
        },
        {
            heading:"Full Stack Developer",
            success: "Contract Base",
            subHeading:"Rass Tech Information, Jaipur",
            subHeadingLink:"https://www.linkedin.com/company/rass-info-pvt-ltd",
            time:"Jan 2022 - Jun 2022"
        },
        {
            heading:"Online Store Manager (eBay, ETSY)",
            success: "Full Time",
            subHeading:"Star Born Jewellery, Jaipur",
            time:"2013 -  Dec 2021"
        },
    ]

    useEffect(() => {
        document.title = `Know About Me - Mohit Agarwal | Portfolio`
        
    },[])
      
  return (
    <PageLayout>
        <div className="about">
            <section>
                <h2 className="heading-text">About Me</h2>
                <p className="content-text">Dedicated and innovative Front End Developer with 2 years of experience in creating dynamic and user-friendly web applications. Proficient in HTML5, CSS3, JavaScript, and modern front end frameworks like React.js. Skilled in creating responsive designs and ensuring cross-browser compatibility. Seeking to expand expertise in backend technologies such as Node.js, MongoDB, AWS, and React Native.</p>
            </section>
            <section>
                <h2 className="heading-text">Work Experience</h2>
                {
                    experienceList?.map((dt, index) => <AboutDetailCard key={index} data={dt}/>)
                }
            </section>
            {
                Object.keys(collegeContent)?.length > 0 && 
                    <section>
                        <h2 className="heading-text">Education</h2>
                        <AboutDetailCard data={collegeContent}/>
                    </section>
            }
        </div>
    </PageLayout>
  )
}

export default About
