import { BiLogoGithub } from "react-icons/bi"
import { BsLink45Deg } from "react-icons/bs"
import { Link, useNavigate } from "react-router-dom"
import React from "react"

const ProjectCard = ({ project }) => {

  const navigate = useNavigate()

  const navigateHandler = () => {
    if(window.location.href?.split('/')?.[3] === ""){
      navigate(`projects/${project?.routeLink}`)
    } else navigate(`${project?.routeLink}`)
  }

  return (
    <div className="project-card">
      <div className="img" onClick={navigateHandler}>
        <img src={project?.imagesList?.[0]?.img} alt={`${project?.name} Project thumbnail`} />
      </div>
      <div className="content">
        <section>
          <h3 title={project?.name} onClick={navigateHandler}>{project?.name}</h3>
          <p onClick={navigateHandler} dangerouslySetInnerHTML={{__html: project?.detailedDescription?.[0]}} />
        </section>
        <footer>
          <div className="flex" onClick={navigateHandler}>
              <span title={project?.techUsed?.map((tech, index) =>' ' + tech)}>
                <b>Tech Stack : </b>
                {project?.techUsed?.map((tech, index) => (
                  <React.Fragment key={index}>
                    {tech}
                    {index !== project.techUsed.length - 1 && ', '}
                  </React.Fragment>
                ))}
              </span>
          </div>
          <footer className="footer">
              <Link to={project?.deployedLink} target="_blank">
                  <BsLink45Deg />
                  <span>Live Preview</span>
              </Link>
              <Link to={project?.codeLink} target="_blank">
                  <BiLogoGithub />
                  <span>View Code</span>
              </Link>
          </footer>
        </footer>
      </div>
    </div>
  )
}

export default ProjectCard
