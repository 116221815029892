import { Link, useParams } from "react-router-dom"
import { PROJECTS } from "../../JSON/ProjectsData"
import { useEffect, useState } from "react"
import PageLayout from "../Layout/PageLayout"
import ImageSlider from "../HOC/ImageSlider"
import { BsLink45Deg } from "react-icons/bs"
import { BiLogoGithub } from "react-icons/bi"

const DetailedProject = () => {
    const [currentProject, setCurrentProject] = useState({})
    const { projectID } = useParams()

    useEffect(() => {
        
        setCurrentProject(PROJECTS?.filter(project => project?.routeLink === projectID)?.[0])
        document.title = `${PROJECTS?.filter(project => project?.routeLink === projectID)?.[0]?.name} - Mohit Agawal | Resume`
    },[projectID])

  return (
    <PageLayout>
        <div >
            <section>
                <h2 className="heading-text">{currentProject?.name} <span>[{currentProject?.category}]</span></h2>
                <p className="content-text">{currentProject?.subHeading}</p>
            </section>
            <section>
                <ImageSlider images={currentProject?.imagesList}/>
            </section>
            <section>
                <h1 className="project-type">{currentProject?.type} Project</h1>
                {currentProject?.detailedDescription?.map((detail, inde) => <p className="content-text" key={inde} dangerouslySetInnerHTML={{__html: detail}}></p>)}
                <div className="chips">
                   <h3 style={{color:'gray'}}>Tech used :</h3> {currentProject?.techUsed?.map((dt, ind) => <span key={ind} className="tech-chip">{dt}</span>)}
                </div>
            </section>
            <section className="social-row">
                <div>
                    <BsLink45Deg />
                    <Link to={currentProject?.deployedLink} target="_blank">Live Preview</Link>
                </div>
                { currentProject?.codeLink !== "" && <div>
                    <BiLogoGithub />
                    <Link to={currentProject?.codeLink} target="_blank">Code Link</Link>
                </div> }
            </section>
        </div>
    </PageLayout>
  )
}

export default DetailedProject