
import PageLayout from '../../Layout/PageLayout'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <PageLayout>
        <div className="not-found">
            <p className='colored-text'>The Page you are looking is not Exists. Please go back to <b><Link to='/'>Home</Link></b></p>
        </div>
    </PageLayout>
  )
}

export default NotFound