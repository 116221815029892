import { useState } from "react"

const ProjectFilterBar = ({setCurrentFilter, filteredProjects}) => {
    const [activeOpt, setActiveOpt] = useState('')

    const onFilterChangeHandler = (value) => {
      setActiveOpt(value)
      setCurrentFilter(value)
    }
  return (
    <div className="filter-bar">
        <span className={`label ${activeOpt === "" ? 'active' : ''}`} onClick={() => onFilterChangeHandler('')}>All {activeOpt === "" && `(${filteredProjects?.length})`}</span>
        <span className={`label ${activeOpt === "Frontend" ? 'active' : ''}`} onClick={() => onFilterChangeHandler('Frontend')}>Front End {activeOpt === "Frontend" && `(${filteredProjects?.length})`}</span>
        <span className={`label ${activeOpt === "mobile app" ? 'active' : ''}`} onClick={() => onFilterChangeHandler('mobile app')}>React Native {activeOpt === "mobile app" && `(${filteredProjects?.length})`}</span>
        <span className={`label ${activeOpt === "fullstack" ? 'active' : ''}`} onClick={() => onFilterChangeHandler('fullstack')}>MERN {activeOpt === "fullstack" && `(${filteredProjects?.length})`}</span>
        <span className={`label ${activeOpt === "NPM" ? 'active' : ''}`} onClick={() => onFilterChangeHandler('NPM')}>NPM Package {activeOpt === "NPM" && `(${filteredProjects?.length})`}</span>
        <span className={`label ${activeOpt === "front end game" ? 'active' : ''}`} onClick={() => onFilterChangeHandler('front end game')}>Front End Game {activeOpt === "front end game" && `(${filteredProjects?.length})`}</span>
    </div>
  )
}

export default ProjectFilterBar