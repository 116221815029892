import { Link } from "react-router-dom"

const MobileNavbar = ({ setShowMobileNavbar, WEB_ROUTES }) => {
  return (
    <div className="mobile-navbar" onClick={() => setShowMobileNavbar(false)}>
        <ul>
          {
            WEB_ROUTES?.map((route, index) =><li key={index}><Link to={route?.link}>{route?.label}</Link></li> )
          }
        </ul>
    </div>
  )
}

export default MobileNavbar