const Section = props => {
  return (
    <section className="section">
        <header>
            <h2 className="heading-text">{props.heading}</h2>
            <p className="content-text">{props.subHeading}</p>
        </header>
        <div className="body">{props.children}</div>
    </section>
  )
}

export default Section
