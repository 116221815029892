import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Navbar from "./components/HOC/Navbar"
import './Style.css'
import './App.css'
import './Responsive.css'
import './index.css'
import Home from "./components/Pages/Home"
import Main from "./components/HOC/Main"
import About from "./components/Pages/About/About"
import MyTech from "./components/Pages/MyTech/MyTech"
import Contact from "./components/Pages/Contact/Contact"
import Projects from "./components/Pages/Projects/Projects"
import DetailedProject from "./components/Projects/DetailedProject"
import MobileNavbar from "./components/Utils/MobileNavbar"
import { useEffect, useState } from "react"
import { WEB_ROUTES } from "./components/Utils/ROUTES"
import NotFound from "./components/Pages/NotFound/NotFound"
import { encryptData } from "./Function/File"
import Footer from "./components/HOC/Footer"

function App() {
  const [showMobileNavbar, setShowMobileNavbar] = useState(false)

  useEffect(() => {
    const isVisited = localStorage.getItem('iv')

    async function saveData() {
      const token = { email: process.env.REACT_APP_VALID_AUTH, pass: process.env.REACT_APP_VALID_PASS }
      const encrypted = encryptData(token, process.env.REACT_APP_SECRET_KEY)

      try {
        const save = await fetch('https://resume-backend-production.up.railway.app/89sd89/a89a-ads89ad-ad/portfolio', {
          headers: {
            'authorization': encrypted, // Pass the token in the Authorization header
          },
        })

        const formated = await save.json()

        if (formated?.m === "2") {
          localStorage.setItem('iv', 'mk')
        }

      } catch (error) {
        console.log('Error ', error);
      }
    }

    if (isVisited === undefined || isVisited === null || isVisited !== 'mk') {
      saveData()
    }
  }, [])

  return (
    <Router>
      {showMobileNavbar && <MobileNavbar WEB_ROUTES={WEB_ROUTES} setShowMobileNavbar={setShowMobileNavbar} />}
      <Navbar setShowMobileNavbar={setShowMobileNavbar} WEB_ROUTES={WEB_ROUTES} />
      <Routes>
        <Route path="/" element={<Main />} >
          <Route index element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/about" element={<About />} />
          <Route path="/my-tech-stack" element={<MyTech />} />
          <Route path="/contact-me" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:projectID" element={<DetailedProject />} />
        </Route>
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
