

const IconVSCODE = ({id}) => {
  return (
    <svg id={id} xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 112 112" fill="none">
  <path d="M101.535 17.605L81.354 7.88899C80.2143 7.33948 78.9319 7.15814 77.6846 7.37011C76.4372 7.58208 75.2867 8.17686 74.3925 9.07199L8.33 69.3C7.52847 70.0273 7.04868 71.0432 6.99617 72.1243C6.94366 73.2053 7.32273 74.2629 8.05 75.0645C8.1375 75.159 8.225 75.25 8.3195 75.334L13.713 80.234C14.4178 80.8754 15.325 81.2497 16.277 81.2919C17.229 81.3341 18.1657 81.0416 18.9245 80.465L98.497 20.125C99.098 19.6689 99.8144 19.3897 100.566 19.3186C101.317 19.2476 102.073 19.3877 102.749 19.7231C103.424 20.0585 103.993 20.5758 104.391 21.217C104.789 21.8581 105 22.5975 105 23.352V23.1175C105 21.9656 104.675 20.8372 104.062 19.862C103.449 18.8868 102.573 18.1045 101.535 17.605Z" fill="#0065A9"/>
  <path d="M101.535 94.395L81.354 104.115C80.214 104.662 78.932 104.843 77.685 104.631C76.438 104.419 75.2875 103.825 74.3925 102.932L8.33 42.7C7.52847 41.9727 7.04868 40.9568 6.99617 39.8758C6.94366 38.7947 7.32273 37.7371 8.05 36.9355C8.1375 36.841 8.225 36.75 8.3195 36.666L13.713 31.766C14.4185 31.1219 15.3277 30.7459 16.2821 30.7037C17.2365 30.6615 18.1754 30.9557 18.935 31.535L98.497 91.875C99.098 92.3311 99.8144 92.6104 100.566 92.6814C101.317 92.7524 102.073 92.6123 102.749 92.2769C103.424 91.9415 103.993 91.4242 104.391 90.783C104.789 90.1419 105 89.4025 105 88.648V88.9C104.996 90.0488 104.669 91.1733 104.057 92.1451C103.444 93.1168 102.57 93.8965 101.535 94.395Z" fill="#007ACC"/>
  <path d="M81.354 104.115C80.214 104.662 78.9319 104.843 77.685 104.631C76.438 104.419 75.2875 103.825 74.3925 102.932C74.8951 103.428 75.533 103.764 76.2261 103.899C76.9192 104.034 77.6368 103.962 78.289 103.691C78.9411 103.42 79.4988 102.963 79.8921 102.376C80.2854 101.79 80.4969 101.1 80.5 100.394V11.606C80.5006 10.8965 80.2906 10.2028 79.8966 9.61277C79.5026 9.02274 78.9423 8.56291 78.2868 8.29155C77.6313 8.02019 76.9099 7.9495 76.2142 8.08843C75.5184 8.22737 74.8795 8.56968 74.3785 9.072C75.2728 8.1772 76.4231 7.58239 77.6702 7.36983C78.9174 7.15727 80.1997 7.33747 81.34 7.8855L101.517 17.5875C102.559 18.0845 103.438 18.8658 104.054 19.8412C104.671 20.8165 104.998 21.9463 105 23.1V88.9C105 90.0528 104.675 91.1823 104.061 92.1582C103.448 93.1341 102.571 93.9168 101.531 94.416L81.354 104.115Z" fill="#1F9CF0"/>
</svg>
  )
}

export default IconVSCODE
